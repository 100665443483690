<template lang="">
  <b-card class="mb-0" :title="$t('settings.email.manage_emails')">
    <b-overlay :show="loading" rounded="sm" no-fade>
      <validation-observer ref="updateValidation">
        <b-row class="p-2">
          <b-col cols="6" sm="12" md="6" lg="6">
            <b-form-group label-for="email" :label="$t('settings.email.mail_to')">
              <validation-provider
                #default="{ errors }"
                :name="$t('settings.email.mailTo')"
                rules="required|email"
              >
                <b-form-input
                  id="mailTo"
                  v-model="mailTo"
                  name="mailTo"
                  :state="errors.length > 0 ? false : null"
                  :placeholder="$t('settings.email.mail_to')"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6" sm="12" md="6" lg="6">
            <b-form-group label-for="email" :label="$t('settings.email.mail_cc')">
              <validation-provider
                #default="{ errors }"
                :name="$t('settings.email.mailCC')"
                rules="required|email"
              >
                <b-form-input
                  id="mailCC"
                  v-model="mailCC"
                  name="mailCC"
                  :state="errors.length > 0 ? false : null"
                  :placeholder="$t('settings.email.mail_cc')"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row align-h="end" class="p-2">
          <b-col v-if="$Can('edit_emails')" cols="12" md="3">
            <div class="d-flex align-items-center justify-content-end">
              <b-button variant="success" @click="updateEmails">
                <span class="text-nowrap">{{$t('settings.email.update_emails')}}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </validation-observer>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormRating,
  VBModal,
  BTabs,
  BTab,
  BCardText,
  BIcon,
} from "bootstrap-vue";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import settingsStoreModule from "../../settingsStoreModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email, min, max } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import axios from "axios";
import axiosIns from "@/libs/axios";
import router from "@/router";
import moment from "moment";
import "animate.css";
import _ from "lodash";
import { mapState, mapGetters } from "vuex";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BTabs,
    BTab,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    BIcon,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
  },

  directives: {
    Ripple,
    "b-modal": VBModal,
  },

  setup() {
    const SETTINGS_APP_STORE_MODULE_NAME = "settings";
    // Register module
    if (!store.hasModule(SETTINGS_APP_STORE_MODULE_NAME))
      store.registerModule(SETTINGS_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SETTINGS_APP_STORE_MODULE_NAME))
        store.unregisterModule(SETTINGS_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    return {
      mailTo: null,
      mailCC: null,
      loading: false,
      currentMailTo: null,
      currentMailCC: null,
    };
  },
  computed: {
    user: {
      get() {
        return this.$store.getters["authUser"];
      },
      set(newValue) {
        return this.$store.dispatch("setUser", newValue);
      },
    },
  },
  mounted() {
    this.getEmails();
  },
  methods: {
    getEmails() {
      this.loading = true;
      store
        .dispatch("settings/fetchEmails")
        .then((res) => {
          if (res.data.length > 0) {
            res.data.forEach((email) => {
              if (email.Name == "emailCC") {
                this.mailCC = email.Value ?? null;
                this.currentMailCC = email.Value ?? null;
              }

              if (email.Name == "emailTo") {
                this.mailTo = email.Value ?? null;
                this.currentMailTo = email.Value ?? null;
              }
            });
          }
          this.loading = false;
        })
        .catch((error) => {
          this.databaseErrorMessage();
          console.log(error);
        });
    },
    updateEmails() {
      this.$refs.updateValidation.validate().then((success) => {
        if (success) {
          if (
            this.currentMailCC == this.mailCC &&
            this.currentMailTo == this.mailTo
          ) {
            this.noChangesToastMessage();
          } else {
            this.loading = true;
            store
              .dispatch("settings/updateEmails", {
                emails: {
                  emailTo: this.mailTo,
                  emailCC: this.mailCC,
                },
              })
              .then((res) => {
                if (res.data.info == true) {
                  this.loading = false;
                  this.getEmails();
                  this.updateEmailToastMessage();
                } else {
                  this.loading = false;
                  this.wrongToastMessage(res.data.message);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        }
      });
    },
    updateEmailToastMessage(val) {
    this.$toast({
      component: ToastificationContent,
      props: {
        title: "Successfully",
        text:   this.$t('settings.email.email_update_success'),
        icon: "ThumbsUpIcon",
        variant: "success",
      },
    });
  },
  databaseErrorMessage(val) {
    this.$toast({
      component: ToastificationContent,
      props: {
        title: "Error",
        text: this.$t('settings.email.database_connect_error'),
        icon: "AlertTriangleIcon",
        variant: "danger",
      },
    });
  },
  noChangesToastMessage(val) {
    this.$toast({
      component: ToastificationContent,
      props: {
        title: "Error",
        text: this.$t('settings.email.make_not_any_changes'),
        icon: "AlertTriangleIcon",
        variant: "danger",
      },
    });
  },
  },
  
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
